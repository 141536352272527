@import "@fontsource/inter/variable-full.css";

body {
  @apply text-dark bg-background;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.walls {
  background: url("./assets/list.png") bottom no-repeat, #c9c5bc;
}

.room-wrapper {
  width: 100%;
  height: 100%;
  perspective: 440px;

  .room {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-50px);

    .room__face {
      position: absolute;

      &--front {
        transform: rotateY(0deg) translateZ(50px);
      }

      &--back {
        background: url("./assets/list.png") bottom repeat-x,
          radial-gradient(#cecac2, #cfcbc3 30%, #bdb9b0);
      }

      &--left {
        left: 50px;
        background: url("./assets/list.png") bottom no-repeat,
          linear-gradient(-90deg, #c5c1b9, #c8c4bb 65%, #bdb9b0);
      }

      &--right {
        background: url("./assets/list.png") bottom no-repeat,
          linear-gradient(-90deg, #bdb9b0, #d1cdc5 65%, #cfcbc3);
      }

      &--top {
        background: linear-gradient(180deg, #bab6ae, #bdb9b0 65%, #c5c1b8);
      }

      &--front,
      &--back {
        width: 100%;
        height: 100%;
      }

      &--left,
      &--right {
        height: 100%;
      }

      &--top {
        width: 100%;
        top: 50px;
      }
    }
  }
}

.wardrobe-wrapper {
  height: 100%;
  perspective: 440px;

  .wardrobe {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-75px);

    .wardrobe__face {
      position: absolute;

      &--front {
        border: 1px solid #1d1211;
        transform: rotateY(0deg) translateZ(25px);
        transform-style: preserve-3d;

        &--grid {
          &:before,
          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background-color: #1d1211;
            position: absolute;
            top: 0;
          }

          &:before {
            left: 33.3%;
          }

          &:after {
            left: 66.6%;
          }
        }
      }

      &--back {
        border: 1px dashed #1d1211;
      }

      &--left {
        left: 50px;
        transform: rotateY(-90deg) translateZ(75px);
      }

      &--front,
      &--back {
        width: 100%;
        height: 100%;
      }

      &--left,
      &--right {
        width: 50px;
        height: 100%;
        border-top: 1px dashed #1d1211;
        border-bottom: 1px dashed #1d1211;
      }
    }
  }
}

.door-track {
  &-2 {
    margin-top: 3px;
  }

  &-3 {
    margin-top: 6px;
  }
}

@screen lg {
  .door-track {
    &-2 {
      margin-top: 5px;
    }

    &-3 {
      margin-top: 10px;
    }
  }
}

.wardrobe-wrapper.placement {
  &-full {
    @apply w-full m-auto;
  }

  &-center .wardrobe,
  &-center .trackbar-perspective-wardrobe {
    @apply m-auto;
  }

  &-right .wardrobe,
  &-right .trackbar-perspective-wardrobe {
    @apply ml-auto;
  }
}

.toggle-switch {
  div {
    &:before {
      content: "";
      @apply absolute w-4 h-4 bg-white rounded-full;
      left: 3px;
      transition: all 0.5s;
    }

    &:after {
      @apply ml-auto mr-5;
    }
  }

  input:checked + div {
    &:before {
      @apply transform -translate-x-full;
      left: calc(100% - 3px);
    }

    &:after {
      @apply mr-auto ml-5;
    }
  }
}

.trackbar-perspective-wrapper {
  width: 100%;
  perspective: 440px;

  .trackbar-perspective-room {
    transform-style: preserve-3d;
    transform: translateZ(-50px);

    &--front {
      width: 100%;
      transform: translateZ(50px);

      .trackbar-perspective-wardrobe-wrapper {
        .trackbar-perspective-wardrobe {
          transform-style: preserve-3d;
          transform: translateZ(-75px);

          .track-bar {
            transform: translateZ(25px);
          }
        }
      }
    }
  }
}

.track-bar {
  .line {
    height: 3px;

    &-active {
      height: 5px;
    }
  }
}

.preserve-3d {
  transform-style: preserve-3d;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#toolbar {
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #dddddd;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #707070;
  }
}

@import "@reach/slider/styles.css";

[data-reach-slider-track][data-orientation="horizontal"] {
  background: none;
  border-radius: 0;
  &::before {
    top: -19px;
    border-bottom: thin black dotted;
  }
}
[data-reach-slider-range] {
  display: none;
}
[data-reach-slider-handle] {
  background: #000;
  width: 8px;
  border-radius: 3px;
}

.pointer-hand {
  animation: 1.9s linear infinite forwards hand;
}
.pointer-arrow {
  animation: 1.9s linear infinite forwards arrow;
}

@keyframes hand {
  0% {
    transform: translateY(0);
  }
  52.63% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes arrow {
  0% {
    transform: translate(29px, 18px) rotate(-90deg) scaleX(1.3);
  }
  52.63% {
    transform: translate(29px, 18px) rotate(-90deg) scaleX(1);
  }
  100% {
    transform: translate(29px, 18px) rotate(-90deg) scaleX(1.3);
  }
}
